/*
@contract listing-card-skeleton {
  height: Height
  carousel-height: Height
  content-gap: Gap
  content-padding: Padding
}
*/

.listingCardSkeleton {
  padding-bottom: 0;
  height: var(--listing-card-skeleton-height);
}

.listingCardSkeletonInner {
  display: flex;
  flex-direction: column;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.listingCardSkeletonCarousel {
  height: var(--listing-card-skeleton-carousel-height);
  background: var(--color-gray-200);
}

.listingCardSkeletonContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--listing-card-skeleton-content-padding);
  gap: var(--listing-card-skeleton-content-gap);
}

.listingCardSkeletonBlocks {
  height: 100%;
  flex: 1;
  gap: var(--space-4x);
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.listingCardSkeletonBlock {
  height: 19px;
  background: var(--color-gray-200);
  border-radius: var(--border-radius-2x);
}

.listingCardSkeletonCta {
  height: 35px;
}
